/* Estilo para a div pai (container) */
.container {
  width: 100%; /* 100% da largura do elemento pai (normalmente o corpo do documento) */
  height: auto;
  background-color: #252525; /* Cor de fundo opcional */
  flex-direction: column;
}
/* Estilo para a div interna (responsiva) */

.header-container {
  width: 100%;
  height: 10vh;
  background-color: #000000; /* Cor de fundo opcional */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-div-left {
  width: 10%;
  height: auto;
  position: relative;
  margin-left: 2%;
  display: flex; /* Adiciona a propriedade para tornar a div um elemento flexbox */
}

.header-div-left-content {
  display: block; /* Transforma o elemento em bloco para ocupar a largura total da div-pai */
  width: 100%; /* Ocupa toda a largura da div-pai */
  height: 100%; /* Ocupa toda a altura da div-pai */
  position: absolute; /* Permite que o elemento se posicione corretamente na div-pai */
  background-image: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/global/dota2_logo_horiz.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
}

@media (max-width: 767px) {
  /* Aplique estilos específicos para telas menores, como dispositivos móveis */
  a.header-div-left-content {
    background-image: url('../../ressource/dota2logo_hor.png');
  }
  .banner-container{
    aspect-ratio: 16 / 9; /* Relação de aspecto desejada (exemplo: 16:9) */
  }
}

@media (min-width: 767px) {
  .banner-container{
    height: 40vh;
  }
}

.header-div-center {
  width: 10%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.header-div-rigt{
  height: auto;
  margin-right: 2%;
  display: flex; /* Adiciona a propriedade para tornar a div um elemento flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
}


.header-content-div-right{
  color: rgb(205, 202, 255); /* Define a cor do texto como branca */
  font-weight: bold; /* Define a fonte como negrito (bold) */
}


.banner-container {
  width: 100%;
  background-color: #000000; /* Cor de fundo opcional */
}

.banner {
  width: 100%; /* Largura da imagem */
  height: 100%; /* Altura da imagem */
  background-size: contain;
  background-image: url('../../ressource/dota216bits.gif');
}

.content-container{
  background-color: #242424; /* Cor de fundo opcional */
}

.content{
  display: flex;
  flex-direction: column;
}

/*

*/


.link-imagem img {
  width: 100%; /* A imagem ocupa toda a largura do elemento <a> */
  height: 100%; /* A imagem ocupa toda a altura do elemento <a> */
  object-fit: cover; /* A imagem será redimensionada para cobrir todo o espaço do elemento <a> */
}


.microheader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Adicione esta linha */
  height: 80px;
  background-color: #000;
  position: relative;
}

.microheader-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.microheader-content-logo {
  width: 200px;
  min-width: 200px;
  height: 50px;
  min-height: 50px;
  background-image: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/global/dota2_logo_horiz.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: .8;
  margin-right: 40px;
  margin-left: 40px;
}

.microheader-navitens {
  flex-grow: 1;
  width: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.microheader-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  font-family: "Radiance","Noto Sans",sans-serif;
  margin-right: 40px;
  margin-left: 40px;
}

.microheader-login-content{
  font-size: 25px;
  margin-bottom: 4px;
  color: #fff;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-family: "Radiance","Noto Sans",sans-serif;
}

.botao{
  background-image: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/global/dota2_logo_horiz.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 200px;
  min-width: 200px;
  height: 50px;
  min-height: 50px;
  margin-right: 40px;
  opacity: .8;
}

.image-column {
  flex: 0 0 auto; /* Fixa a largura da coluna à esquerda */
  width: 20px; /* Defina a largura desejada para a coluna de imagem */
}

.column {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
}

.App {
  padding-top: 1%;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
