.container {
  width: 100%; /* 100% da largura do elemento pai (normalmente o corpo do documento) */
  background-color: #252525; /* Cor de fundo opcional */
  flex-direction: column;
  height: 90vh;
}

/**/

.login-container {
  background-color: #242424; /* Cor de fundo opcional */
  display: flex;
  justify-content: flex-end;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 2%;
  width: 50%;
  background-color: #000000; /* Cor de fundo opcional */
}

input[type="text"],
input[type="password"] {
  margin:2%; /* Espaçamento vertical entre os campos de entrada */
  background-color: #000; /* Cor de fundo preta */
  border: none; /* Remova as bordas padrão */
  border-bottom: 1px solid white; /* Adicione uma borda inferior branca */
  color: white; /* Cor do texto */
  padding: 5px; /* Espaçamento interno para o texto */
  outline: none; /* Remova o contorno ao focar */
}

.button-container {
  display: flex;
  flex-direction: column; /* Coloca os itens em coluna */
  margin: 4%;
  background-color: #000000; /* Cor de fundo opcional */
  float: right;
}

button {
  /* Estilos dos botões */
  background-color: transparent;
  border: 1px solid #ffffff;
  color: white;
  padding: 2% 4%; /* Use valores de porcentagem para o padding */  
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 0;
  text-transform: uppercase;
  margin-top: 10px; /* Espaçamento vertical entre os botões */
  align-self: flex-end; /* Posiciona os botões no canto direito */
}

button:hover {
  background-color: #ffffff; /* Cor de fundo ao passar por cima */
  color: #000000; /* Cor do texto ao passar por cima */
}

.link-button {
  /* Estilos dos links que se comportam como botões */
  display: inline-block; /* Para que os links tenham largura e altura automáticas */
  background-color: transparent;
  border: 1px solid #ffffff;
  color: white;
  padding: 2% 4%; /* Use valores de porcentagem para o padding */
  text-decoration: none; /* Remova a decoração de sublinhado padrão */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 0;
  text-transform: uppercase;
  margin-top: 10px; /* Espaçamento vertical entre os botões */
  align-self: flex-end; /* Posiciona os botões no canto direito */
}

.link-button:hover {
  background-color: #ffffff; /* Cor de fundo ao passar por cima */
  color: #000000; /* Cor do texto ao passar por cima */
}